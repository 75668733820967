import type { TranslateFunction } from '~/shared/types/i18n.type';

export enum TimeVariations {
  Hours1 = '1h',
  Hours24 = '24h',
  Days7 = '7d',
  Days30 = '30d',
  Days60 = '60d',
  Days90 = '90d',
}

function separateNumbersAndLetters(timeLabel: TimeVariations) {
  const matches = timeLabel.match(/(\d+)([a-zA-Z]+)/);

  return matches ? { number: matches[1], letters: matches[2] } : null;
}

export function getLocalizedTimeLabel(timeVariation: TimeVariations, t: TranslateFunction): string {
  const separatedArr = separateNumbersAndLetters(timeVariation);

  if (!separatedArr) return '';

  return `${separatedArr.number}${t(`common.time.${separatedArr.letters}`)}`;
}
