<template>
  <div
    ref="tableWrapper"
    class="de-datatable tw-overflow-auto"
    :class="{
      'de-datatable-ping-left': isShadowVisible,
      'de-datatable-scrolled-right': isScrolledRight,
      'de-datatable-hoverable-rows': rowHover,
    }"
    v-bind="$attrs"
  >
    <table class="de-datatable-table" :class="`de-datatable-${type}`">
      <thead class="de-datatable-thead" :class="theadClass">
        <tr v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :colSpan="header.colSpan"
            :class="[
              header.column.columnDef.meta?.thClass,
              header.column.getCanSort() ? 'tw-cursor-pointer tw-select-none' : '',
              { 'de-datatable-frozen-cell max-md:!tw-left-0': header.column.getIsPinned() },
            ]"
            :style="getCommonPinningStyles(header.column)"
            @click="header.column.getCanSort() ? onSort(header) : undefined"
          >
            <slot :name="getSlotName(header.column.id, 'header')">
              <div class="tw-whitespace-nowrap">
                <FlexRender
                  v-if="!header.isPlaceholder"
                  :render="header.column.columnDef.header"
                  :props="header.getContext()"
                />

                <de-svg-icon
                  v-if="header.column.getIsSorted()"
                  name="chevron-down-filled"
                  class="tw-w-300 tw-h-300"
                  :class="[{ 'tw-transform tw-rotate-180': header.column.getIsSorted() === 'asc' }]"
                />
              </div>
            </slot>
          </th>
        </tr>
      </thead>
      <tbody class="de-datatable-tbody">
        <template v-if="loading">
          <tr v-for="i in pageSize" :key="i">
            <td
              v-for="header in table.getFlatHeaders()"
              :key="header.id"
              :style="getCommonPinningStyles(header.column)"
              :class="header.column.columnDef.meta?.thClass"
            >
              <de-skeleton />
            </td>
          </tr>
        </template>

        <tr v-for="row in table.getRowModel().rows" v-else :key="row.id" @click="onRowClick(row)">
          <td
            v-for="cell in row.getVisibleCells()"
            :key="cell.id"
            :style="getCommonPinningStyles(cell.column)"
            :class="[
              cell.column.columnDef.meta?.tdClass,
              { 'de-datatable-frozen-cell max-md:!tw-left-0': cell.column.getIsPinned() },
            ]"
          >
            <slot :name="getSlotName(cell.column.id, 'body')" v-bind="{ data: cell.row.original }">
              <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
            </slot>
          </td>
        </tr>

        <tr v-if="totalPages < 1 && !loading">
          <td :colspan="table.getFlatHeaders().length">
            <slot name="empty" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <nav v-if="totalPages > 1" class="de-paginator">
    <div class="tw-text-300 tw-leading-400 tw-text-primary-300 max-lg:tw-hidden">
      {{
        t('common.currentPageReportTemplate', {
          first: pageIndex * pageSize + 1,
          last: pageSize * currentPage,
          total: totalPages * pageSize,
        })
      }}
    </div>

    <div class="tw-flex tw-gap-x-4">
      <nuxt-link-locale
        :to="generatePaginationLink(currentPage - 1)"
        class="de-paginator-page de-paginator-prev"
        :class="{ 'is-disabled': !table.getCanPreviousPage() }"
      >
        <de-svg-icon name="chevron-down-filled" class="tw-rotate-90" />
      </nuxt-link-locale>

      <div class="tw-flex tw-gap-x-1">
        <nuxt-link-locale
          v-if="currentPage"
          :to="generatePaginationLink(1)"
          class="de-paginator-page"
          :class="{ 'is-active tw-pointer-events-none': currentPage === 1 }"
        >
          1
        </nuxt-link-locale>

        <!-- Dots before the current range -->
        <div v-if="showStartDots" class="de-paginator-dots">
          <span>...</span>
        </div>

        <!-- Page numbers around the current page -->
        <nuxt-link-locale
          v-for="page in pages"
          :key="page"
          :to="generatePaginationLink(page)"
          class="de-paginator-page"
          :class="{ 'is-active tw-pointer-events-none': currentPage === page }"
        >
          {{ page }}
        </nuxt-link-locale>

        <!-- Dots after the current range -->
        <div v-if="showEndDots" class="de-paginator-dots">
          <span>...</span>
        </div>

        <!-- Last page always visible -->
        <nuxt-link-locale
          :to="generatePaginationLink(totalPages)"
          class="de-paginator-page"
          :class="{ 'is-active tw-pointer-events-none': currentPage === totalPages }"
        >
          {{ totalPages }}
        </nuxt-link-locale>
      </div>

      <nuxt-link-locale
        :to="generatePaginationLink(currentPage + 1)"
        class="de-paginator-page de-paginator-next"
        :class="{ 'is-disabled': !table.getCanNextPage() }"
      >
        <de-svg-icon name="chevron-down-filled" class="tw--rotate-90" />
      </nuxt-link-locale>
    </div>
  </nav>
</template>

<script setup lang="ts" generic="T extends RowData">
import { FlexRender } from '@tanstack/vue-table';
import type { Header, Table, RowData, Column, Row } from '@tanstack/vue-table';
import type { CSSProperties } from 'vue';
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { DataTableSizeOptions } from '~/shared/components/lib/data-table/data-table.entity';
import type { Class } from '~/shared/types/base.type';
import type { Path } from '~/data/model/table-column.model';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import DeSkeleton from '~/shared/components/lib/DeSkeleton.vue';
import type { TableColumnSort } from '~/shared/components/table/table.type';

type ReplaceDotWithUnderscore<S extends string> = S extends `${infer L}.${infer R}`
  ? `${L}_${ReplaceDotWithUnderscore<R>}`
  : S;
type PathWithUnderscore<T> = ReplaceDotWithUnderscore<Path<T>>;
type SlotName<Obj> = `body_${PathWithUnderscore<Obj>}` | `header_${PathWithUnderscore<Obj>}`;
type Slots<F> = {
  [S in SlotName<F>]?: (_: { data: T; index: number }) => any;
};

defineSlots<Slots<T> & { empty: [] }>();

const emit = defineEmits<{
  sort: [event: TableColumnSort<T>];
  rowClick: [row: Row<T>];
}>();

const props = withDefaults(
  defineProps<{
    table: Table<T>;
    type?: DataTableSizeOptions;
    loading?: boolean;
    theadClass?: Class;
    rowHover?: boolean;
  }>(),
  {
    type: DataTableSizeOptions.normal,
  },
);

const { t } = useI18n();

function getSlotName(colField, slotType: 'header' | 'body') {
  // Normalize the field for slot naming by replacing dots with underscores
  // This is necessary because slot names cannot contain dots
  const normalizedField = colField.replace(/\./g, '_');

  return `${slotType}_${normalizedField}`;
}

function getCommonPinningStyles(column: Column<T>): CSSProperties {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    width: `${column.getSize()}px`,
    minWidth: `${column.columnDef.minSize}px`,
    maxWidth: `${column.columnDef.maxSize}px`,
  };
}

const maxPagesToShow = ref(4);
const totalPages = computed(() => props.table.getPageCount());
const pageSize = computed(() => props.table.getState().pagination.pageSize);
const pageIndex = computed(() => props.table.getState().pagination.pageIndex);
const currentPage = computed(() => pageIndex.value + 1);

// Compute the pages to display
const pages = computed(() => {
  const range = maxPagesToShow.value - 2; // Number of pages around the current page
  let start = Math.max(2, currentPage.value - Math.floor(range / 2));
  const end = Math.min(totalPages.value - 1, start + range);

  // Adjust start if end is too close to the total pages
  if (end - start < range) {
    start = Math.max(2, end - range);
  }

  // Include pages in the range
  const pageRange = [];
  if (totalPages.value > 1) {
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
  }

  return pageRange;
});
const showStartDots = computed(() => pages.value[0] > 2);
const showEndDots = computed(() => pages.value[pages.value.length - 1] < totalPages.value - 1);

const route = useRoute();
function generatePaginationLink(newPage: number) {
  const query = { ...route.query };

  query.page = String(newPage);

  return { path: route.path, query };
}

function onSort(header: Header<T, any>) {
  emit('sort', {
    id: header.id,
    desc: header.column.getIsSorted() === 'desc',
    sortingFn: header.column.columnDef.sortingFn,
  });
}

function onRowClick(row: Row<T>) {
  emit('rowClick', row);
}

const isShadowVisible = ref(false);
const isScrolledRight = ref(false);
const tableWrapper = ref<HTMLDivElement | null>(null);

function handleScroll(event: Event) {
  const target = event.target as HTMLElement;

  isShadowVisible.value = target.scrollLeft > 0;
  isScrolledRight.value =
    Math.abs(target.scrollWidth - target.clientWidth - target.scrollLeft) <= 1;
}

onMounted(() => {
  tableWrapper.value?.addEventListener('scroll', handleScroll);
});
</script>
